import { useLocalStorage } from '@vueuse/core'

export default function () {
  const drupalUser = useDrupalUser()
  const debugEnabled = useLocalStorage('debugEnabled', () => false)

  const isDebug = computed(() => {
    return drupalUser.value.accessToolbar && debugEnabled.value
  })

  function toggleDebug() {
    if (!drupalUser.value.accessToolbar) {
      return
    }
    debugEnabled.value = !debugEnabled.value
  }

  return {
    isDebug,
    toggleDebug,
  }
}
